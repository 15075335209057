<script lang="ts" setup>
import type { strapiNavigationItem } from '~/interfaces/strapi.interface'
const { locale } = useI18n()
const config = useRuntimeConfig()

const props = defineProps<{
  menu?: strapiNavigationItem[]
  itemClasses: string
  itemsClasses: string
}>()

const transformToLink = (item: strapiNavigationItem) => {
  if (item.related) {
    if (item.related.__contentType === 'api::homepage.homepage') return '/'
    else if (item.related.__contentType === 'api::page.page')
      return `/${item.related.url}`
    else if (item.related.__contentType === 'api::article-list.article-list')
      return '/articles'
    else return item.path
  } else if (item.external || item.type === 'WRAPPER') {
    return item.path
  }
  return undefined
}
</script>

<template>
  <nav v-if="menu" data-test="menu">
    <ul class="flex" :class="[itemsClasses]">
      <li v-for="item in menu" :key="item.id">
        <nuxt-link
          :class="itemClasses"
          data-test="menuItem"
          :to="transformToLink(item)"
          :target="item.newTab ? '_blank' : undefined"
        >
          {{ item.title }}
        </nuxt-link>
        <ul v-if="item.items">
          <li v-for="subItem in item.items" :key="subItem.id">
            <nuxt-link
              :to="transformToLink(subItem)"
              :target="subItem.newTab ? '_blank' : undefined"
            >
              {{ subItem.title }}
            </nuxt-link>
          </li>
        </ul>
      </li>
    </ul>
  </nav>
</template>
