<script lang="ts" setup>
import type { media } from '~/interfaces/blocks.interface'

defineProps<{
  content: media
}>()

const viewVideo = ref(false)
</script>

<template>
  <section>
    <UContainer class="media block" data-test="block">
      <div v-if="content.url || content.file.data">
        <div
          v-if="
            content.file.data && content.file.data.attributes.ext === '.pdf'
          "
        >
          <UButton color="primaryLight" icon="i-fa6-solid-download">{{
            content.file.data.attributes.name
          }}</UButton>
        </div>
        <template v-else>
          <div v-if="!content.url" class="flex justify-center">
            <AkImage
              class="mx-auto"
              :image="content.file"
              :legend-position="content.legend_position"
            />
          </div>
          <div v-else-if="content.url">
            <AkRow justify="center">
              <AkCol :cols="12" :cols-lg="10" :cols-xl="9">
                <div
                  v-if="(content.file.data || content.url) && !viewVideo"
                  class="focus relative duration-300"
                  :class="{ 'cursor-pointer': content.url }"
                  @click="content.url ? (viewVideo = true) : ''"
                >
                  <AkImage
                    v-if="content.file.data"
                    align="center"
                    class="aspect-video"
                    :image="content.file"
                  />
                  <div
                    v-else-if="content.url && !viewVideo"
                    class="bg-primary aspect-video w-full"
                  />
                  <UIcon
                    v-if="content.url"
                    class="absolute left-1/2 top-1/2 h-60 w-60 -translate-x-1/2 -translate-y-1/2 text-white"
                    name="i-fa6-solid-play"
                  />
                </div>
                <iframe
                  v-show="viewVideo"
                  ref="vimeoVideo"
                  class="block aspect-video w-full"
                  :src="
                    viewVideo
                      ? `${content.url
                          .replace('youtu.be', 'youtube.com/embed')
                          .replace('/watch/v=', 'embed/')
                          .replace(
                            'https://vimeo.com',
                            'https://player.vimeo.com/video'
                          )}${content.url.indexOf('?') !== -1 ? '&autoplay=1&muted=1' : '?autoplay=1&muted=1'}`
                      : ''
                  "
                />
              </AkCol>
            </AkRow>
          </div>
        </template>
      </div>
    </UContainer>
  </section>
</template>

<style lang="scss" scoped>
.media {
  url {
    @apply w-full;
    aspect-ratio: 16 / 9;
  }
}
</style>
