<script lang="ts" setup>
import type { strapiMedia } from '~/interfaces/strapi.interface'

const props = withDefaults(
  defineProps<{
    image: strapiMedia
    align?: string
    transform?: string
    legendPosition?: string
    noTwic?: boolean
  }>(),
  {
    legendPosition: '',
    transform: '',
    align: 'start',
    noTwic: false,
  }
)

defineOptions({
  inheritAttrs: false,
})

const config = useRuntimeConfig()

const wealthTwicLink = ref('')

if (config.public.env === 'development' || config.public.env === 'staging') {
  wealthTwicLink.value = 'wealthLocal/'
} else if (config.public.env === 'preprod') {
  wealthTwicLink.value = 'wealthPreprod/'
} else {
  wealthTwicLink.value = ''
}

const legendPosition = computed(() => {
  if (props.legendPosition && props.legendPosition === 'gauche')
    return 'text-left'
  else if (props.legendPosition && props.legendPosition === 'centre')
    return 'text-center'
  else return 'text-right'
})
</script>

<template>
  <template v-if="noTwic">
    <div
      v-if="image && image.data && image.data.attributes.caption"
      class="flex"
      :class="`justify-${align}`"
    >
      <div class="flex w-full flex-col">
        <picture>
          <source
            media="(max-width: 767px)"
            :srcset="`${config.public.twicpics_domain}/${wealthTwicLink}${image.data.attributes.hash}${image.data.attributes.ext}?twic=v1/cover-max=400`"
          />
          <img
            v-bind="$attrs"
            class="block max-w-full"
            :alt="image.data.attributes.alternativeText"
            :src="`${config.public.twicpics_domain}/${wealthTwicLink}${image.data.attributes.hash}${image.data.attributes.ext}?twic=v1/cover-max=600`"
          />
        </picture>

        <span class="text-p4 text-grey-200" :class="[legendPosition]">
          {{ image.data.attributes.caption }}
        </span>
      </div>
    </div>
    <picture v-else-if="image">
      <source
        media="(max-width: 767px)"
        :srcset="`${config.public.twicpics_domain}/${wealthTwicLink}${image.data.attributes.hash}${image.data.attributes.ext}?twic=v1/cover-max=400`"
      />
      <img
        v-bind="$attrs"
        class="block max-w-full"
        :alt="image.data.attributes.alternativeText"
        :src="`${config.public.twicpics_domain}/${wealthTwicLink}${image.data.attributes.hash}${image.data.attributes.ext}?twic=v1/cover-max=600`"
      />
    </picture>
  </template>
  <template v-else>
    <div
      v-if="image && image.data && image.data.attributes.caption"
      class="flex"
      :class="`justify-${align}`"
    >
      <div class="flex w-full flex-col">
        <img
          loading="lazy"
          v-bind="$attrs"
          class="block max-w-full"
          :alt="image.data.attributes.alternativeText"
          :data-twic-src="`media:/${wealthTwicLink}${image.data.attributes.hash}${image.data.attributes.ext}`"
          :data-twic-transform="`${transform}`"
          :src="`${config.public.twicpics_domain}/${wealthTwicLink}${image.data.attributes.hash}${image.data.attributes.ext}?twic=v1/output=preview`"
        />

        <span class="text-p4 text-grey-200" :class="[legendPosition]">
          {{ image.data.attributes.caption }}
        </span>
      </div>
    </div>
    <img
      v-else-if="image && image.data"
      v-bind="$attrs"
      loading="lazy"
      class="block max-w-full"
      :alt="image.data.attributes.alternativeText"
      :data-twic-src="`media:/${wealthTwicLink}${image.data.attributes.hash}${image.data.attributes.ext}`"
      :data-twic-transform="`${transform}`"
      :src="`${config.public.twicpics_domain}/${wealthTwicLink}${image.data.attributes.hash}${image.data.attributes.ext}?twic=v1/output=preview`"
    />
  </template>
</template>
