import type { strapiSeo, strapiSeoSocial } from '~/interfaces/strapi.interface'

export const useSeo = (seo: strapiSeo) => {
  const meta = []
  const link = []

  const route = useRoute()
  const config = useRuntimeConfig()

  meta.push({
    name: 'description',
    content: seo.metaDescription,
  })

  if (seo.keywords) {
    meta.push({
      name: 'keywords',
      content: seo.keywords,
    })
  }

  if (seo.metaSocial.length) {
    seo.metaSocial.forEach((network: strapiSeoSocial) => {
      if (network.socialNetwork === 'Facebook') {
        if (network.image.data) {
          meta.push({
            property: 'og:image',
            content: useStrapiMedia(network.image.data.attributes.url),
          })
        } else if (seo.metaImage.data) {
          meta.push({
            property: 'og:image',
            content: useStrapiMedia(seo.metaImage.data.attributes.url),
          })
        }

        meta.push({
          property: 'og:title',
          content: network.title,
        })

        meta.push({
          property: 'og:description',
          content: network.description,
        })
      } else if (network.socialNetwork === 'Twitter') {
        if (network.image.data) {
          meta.push({
            name: 'twitter:image',
            content: useStrapiMedia(network.image.data.attributes.url),
          })
        } else if (seo.metaImage.data) {
          meta.push({
            name: 'twitter:image',
            content: useStrapiMedia(seo.metaImage.data.attributes.url),
          })
        }

        meta.push({
          name: 'twitter:title',
          content: network.title,
        })

        meta.push({
          name: 'twitter:description',
          content: network.description,
        })
      }
    })
  } else if (seo.metaImage.data) {
    meta.push({
      property: 'og:image',
      content: useStrapiMedia(seo.metaImage.data.attributes.url),
    })
  }

  if (seo.canonical) {
    link.push({
      rel: 'canonical',
      href: seo.canonical,
    })
  } else {
    link.push({
      rel: 'canonical',
      href: `${config.public.baseUrl}${route.href}`,
    })
  }

  link.push({
    rel: 'alternate',
    hreflang: 'fr',
    href: `${config.public.baseUrl}${route.href}`,
  })

  link.push({
    rel: 'alternate',
    hreflang: 'x-default',
    href: `${config.public.baseUrl}${route.href}`,
  })

  return { meta, link }
}
